<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :isExcelDown="false"
          :editable="editable"
          :noDataLabel="$language('점검계획할 설비를 추가하세요. 저장할 시 점검결과를 등록 할 수 있습니다.')"
          selection="multiple"
          :checkClickFlag="false"
          cardClass="topcolor-orange"
          @linkClick="linkClick"
          rowKey="minEquipmentCheckId"
        >
          <!-- 버튼 영역 -->
          <template v-slot:suffixTitle>
            <font color="#C10015">
              {{$language('※ 법정설비가 아닌 경우 점검유형은 자체(일상)로 고정됩니다.')}}
            </font>
          </template>
          <template slot="table-button">
            <q-btn-group outline>
              <c-btn v-if="popupParam.equipmentCd && editable" :showLoading="false" label="추가" icon="add" @btnClicked="addRow" />
              <c-btn v-if="!popupParam.equipmentCd && editable" :showLoading="false" label="설비선택" icon="add" @btnClicked="add" />
              <c-btn v-if="editable && saveList.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="remove" />
              <c-btn
                v-if="editable && saveList.length > 0"
                :url="insertUrl"
                :isSubmit="isSave"
                :param="saveList"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'sopMocName'">
              <q-btn
                class="tableinnerBtn tableinnerUserSearch"
                :class="{'tableinnerUserSearch-left': true,  'tableinnerUserSearch-right': true }"
                flat
                color="blue-6"
                :disable="props.row.disable"
                :label="props.row.sopMocName"
                icon="search"
                @click.stop="linkClick(props.row)"
                />
            </template>
            <template v-if="col.name === 'statusCd'">
              <q-chip
                :color="setTagColor(props.row.statusCd)"
                outline square
                :class="props.row.statusCd ==='MCSC000020' ? 'blinking' : null"
                text-color="white">
                <q-avatar v-if="props.row.statusCd==='MCSC000020'" icon="alarm" color="red" text-color="white" />
                {{setTagName(props.row.statusCd)}}
              </q-chip>
            </template>
            <template v-if="col.name === 'equipmentCheckKindCd'">
              <c-select
                v-if="props.row.lawEquipmentFlag === 'Y'"
                stype="tableselect"
                :disabled="props.row.disable"
                codeGroupCd="MDM_CHECK_KIND_CD"
                :editable="editable"
                type="edit"
                itemValue="code"
                itemText="codeName"
                v-model="props.row['equipmentCheckKindCd']"
                @datachange="datachange(props)"
                >
              </c-select>
              <c-select
                v-else
                stype="tableselect"
                :disabled="true"
                :editable="editable"
                type="edit"
                itemValue="code"
                itemText="codeName"
                :comboItems="[
                  { code: 'MCKC000005', codeName: '자체(일상)' },
                ]"
                v-model="props.row['equipmentCheckKindCd']"
                @datachange="datachange(props)"
                >
              </c-select>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-plan',
  components: {
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        equipmentCd: '',
        plantCd: '',
        checkDate: '',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [],
        data: [],
        height: '850px'
      },
      title: '',
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      completeUrl: '',
      deleteUrl: '',
      data: {
        equipmentCd: '',
      },
      isSave: false,
      updateMode: false,

      selectDate: '', 
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveList: [],
      colorItems: [
        { stepperMstCd: '', stepperMstNm: '작성중', colorClass: 'gray-6' },
        { stepperMstCd: 'MCSC000005', stepperMstNm: '계획수립', colorClass: 'blue' },
        { stepperMstCd: 'MCSC000010', stepperMstNm: '점검중', colorClass: 'orange' },
        { stepperMstCd: 'MCSC000015', stepperMstNm: '점검완료', colorClass: 'green' },
        { stepperMstCd: 'MCSC000020', stepperMstNm: '지연', colorClass: 'red' },
      ],
      row: null,
    };
  },
  watch: {
    'grid.data' () {
      this.saveList = this.$_.filter(this.grid.data, { checkStatusCd: 'MCSC000005' })
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // list setting
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.insert.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.plan.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.complete.url;
      this.getUrl = selectConfig.sop.min.equipment.annual.get.url;

      this.title = this.popupParam.title;

      this.getList();

      this.setHeader();
    },
    setHeader() {
      this.grid.columns = [
        {
          name: "statusCd",
          field: "statusCd",
          label: "진행상태",
          style: 'width:100px',
          type: 'custom',
          align: "center",
          sortable: false,
        },
        {
          required: true,
          setHeader: true,
          name: 'checkDeptName',
          field: 'checkDeptName',
          label: '점검부서',
          style: 'width:150px',
          type: 'dept',
          align: 'center',
          sortable: false,
          deptCd: 'checkDeptCd',
        },
        {
          name: 'equipmentTypeName',
          field: 'equipmentTypeName',
          label: '설비유형',
          style: 'width:100px',
          align: 'center',
          sortable: false
        },
        {
          name: 'equipmentNo',
          field: 'equipmentNo',
          label: '설비관리번호',
          style: 'width:100px',
          align: 'center',
          sortable: false
        },
        {
          name: 'equipmentName',
          field: 'equipmentName',
          label: '설비명',
          style: 'width:180px',
          align: 'left',
          sortable: false
        },
        {
          name: 'sopMocName',
          field: 'sopMocName',
          label: 'MOC번호',
          align: 'center',
          style: 'width:180px',
          sortable: false,
          type: "custom",
        },
        {
          required: true,
          name: "equipmentCheckKindCd",
          field: "equipmentCheckKindCd",
          label: "점검유형",
          style: 'width:100px',
          type: 'custom',
          headType: 'select',
          align: "center",
          setHeader: true,
          comboItems: [
            { code: 'MCKC000005', codeName: '자체(일상)' },
            { code: 'MCKC000010', codeName: '법정(정기)' },
          ],
          sortable: false,
        },
        {
          name: 'checkScheduleUserName',
          field: 'checkScheduleUserName',
          label: '점검예정자',
          required: true,
          align: 'center',
          style: 'width:120px',
          setHeader: true,
          type: 'user',
          userId: 'checkScheduleUserId',
          sortable: false,
        },
        {
          setHeader: true,
          name: 'checkScheduleDate',
          field: 'checkScheduleDate',
          label: '점검예정일',
          required: true,
          align: 'center',
          style: 'width:110px',
          type: 'date',
          sortable: false,
        },
        {
          name: 'inspectionCycleName',
          field: 'inspectionCycleName',
          label: '법정주기',
          style: 'width:80px',
          align: 'center',
          sortable: false
        },
        {
          name: 'selfCycleName',
          field: 'selfCycleName',
          label: '자체주기',
          style: 'width:80px',
          align: 'center',
          sortable: false
        },
        {
          name: 'recentInspectionDate',
          field: 'recentInspectionDate',
          label: '최근법정점검일',
          style: 'width:100px',
          align: 'center',
          sortable: false
        },
        {
          name: 'recentSelfDate',
          field: 'recentSelfDate',
          label: '최근자체점검일',
          style: 'width:100px',
          align: 'center',
          sortable: false
        },
        {
          name: 'relatedLawsName',
          field: 'relatedLawsName',
          label: '관련법규',
          align: 'left',
          style: 'width:250px',
          sortable: false
        },
      ]
    },
    getList() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.equipmentCd, this.popupParam.checkDate);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.updateMode = true;
        },);

        this.$http.url = selectConfig.mdm.equipment.list.url;
        this.$http.param = {equipmentCd: this.popupParam.equipmentCd, plantCd: this.popupParam.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.data = _result.data[0];
          }
        },);
      }
    },
    add() {
      this.popupOptions.title = '연간점검계획 대상 설비 검색'; 
      this.popupOptions.param = {
        type: 'multiple',
        title: '점검'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    addRow() {
      this.grid.data.splice(0, 0, {
        minEquipmentCheckId: uid(),
        plantCd: this.data.plantCd,
        plantName: this.data.plantName,
        checkDeptCd: '',
        checkDeptName: '',
        sopMocId: '',
        sopMocName: '',
        equipmentCheckKindCd: this.data.lawEquipmentFlag === 'N' ? 'MCKC000005' : null, 
        statusCd: '',
        checkStatusCd: 'MCSC000005',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: this.data.equipmentTypeCd, 
        equipmentTypeName: this.data.equipmentTypeName,
        equipmentNo: this.data.equipmentNo,
        equipmentCd: this.data.equipmentCd, 
        equipmentName: this.data.equipmentName,
        inspectionCycleName: this.data.inspectionCycleName,
        inspectionCycleCd: this.data.inspectionCycleCd,
        lawEquipmentFlag: this.data.lawEquipmentFlag,
        selfCycleName: this.data.selfCycleName,
        selfCycleCd: this.data.selfCycleCd,
        recentInspectionDate: this.data.recentInspectionDate,
        recentSelfDate: this.data.recentSelfDate,
        relatedLawsName: this.data.relatedLawsName,
        relatedLaws: this.data.relatedLaws,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        noPlanResultEnrollFlag: 'N',
        editFlag: 'C',
      })
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.grid.data.splice(0, 0, {
            minEquipmentCheckId: uid(),
            plantCd: _item.plantCd,
            plantName: _item.plantName,
            checkDeptCd: '',
            checkDeptName: '',
            sopMocId: '',
            sopMocName: '',
            equipmentCheckKindCd: _item.lawEquipmentFlag === 'N' ? 'MCKC000005' : null, 
            statusCd: '',
            checkStatusCd: 'MCSC000005',
            checkScheduleUserName: '', 
            checkScheduleUserId: '',
            checkScheduleDate: '',
            equipmentTypeCd: _item.equipmentTypeCd, 
            equipmentTypeName: _item.equipmentTypeName,
            equipmentNo: _item.equipmentNo,
            equipmentCd: _item.equipmentCd, 
            equipmentName: _item.equipmentName,
            inspectionCycleName: _item.inspectionCycleName,
            lawEquipmentFlag: _item.lawEquipmentFlag,
            inspectionCycleCd: _item.inspectionCycleCd,
            selfCycleName: _item.selfCycleName,
            selfCycleCd: _item.selfCycleCd,
            recentInspectionDate: _item.recentInspectionDate,
            recentSelfDate: _item.recentSelfDate,
            relatedLawsName: _item.relatedLawsName,
            relatedLaws: _item.relatedLaws,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            noPlanResultEnrollFlag: 'N',
            editFlag: 'C',
          })
          // }
        })
      }
    },
    saveInspection() {
      if (this.grid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '설비를 추가하세요.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      this.emit('SAVE');
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { minEquipmentCheckId: item.minEquipmentCheckId })
          })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    linkClick(row) {
      this.popupOptions.title = "설비관련 MOC 검색"; 
      this.popupOptions.param = {
        type: 'single'
      };
      this.row = row;
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.row.sopMocName = data[0].mocTypeName + ' / ' + data[0].mocNo
        this.row.sopMocId = data[0].sopMocId
        if (this.row.editFlag !== 'C') {
          this.row.editFlag = 'U'
          this.row.chgUserId = this.$store.getters.user.userId
        } 
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
  }
};
</script>